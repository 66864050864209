import axios, { AxiosError, AxiosHeaders, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { refreshToken as handleRefreshToken } from "src/services/auth";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const baseURL = process.env.REACT_APP_BASE_URL;

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined;
};

let isRefreshing = false;
// eslint-disable-next-line no-unused-vars
let refreshSubscribers: ((token: string) => void)[] = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  try {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      const mHeaders = AxiosHeaders.from({
        Authorization: `Bearer ${accessToken}`,
      }) as AxiosRequestHeaders;

      if (mHeaders) {
        config.headers = mHeaders;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return config;
};

api.interceptors.request.use(onRequest);

const onTokenRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

// eslint-disable-next-line no-unused-vars
const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;

    if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        try {
          const newToken = await handleRefreshToken(refreshToken);
          // const newToken = {
          //   access_token: "newAccessToken",
          //   refresh_token: "newRefreshToken",
          // };

          if (newToken?.access_token) {
            localStorage.setItem("access_token", newToken.access_token);
            localStorage.setItem("refresh_token", newToken.refresh_token);

            originalRequest.headers["Authorization"] = `Bearer ${newToken.access_token}`;

            onTokenRefreshed(newToken.access_token);

            if (typeof originalRequest.data === "string") {
              originalRequest.data = JSON.parse(originalRequest.data);
            }

            return api(originalRequest);
          } else {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            // window.location.href = `/login${window.location.search}`;
          }
        } catch (refreshError) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          // window.location.href = `/login${window.location.search}`;
        } finally {
          isRefreshing = false;
        }
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        // window.location.href = `/login${window.location.search}`;
      }
    }

    return Promise.reject(error);
  },
);

export default api;
