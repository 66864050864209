import { lazy } from "react";

import { Loader } from "src/components/common";

import LoginPage from "src/pages/login";
import MainLayout from "src/layouts/MainLayout";
import AuthLayout from "src/layouts/AuthLayout";
import TradingView from "src/pages/trading-view";
import PaymentPage from "src/pages/payment";
import CallbackPage from "src/pages/sign-up-with-email/callback";
import GoogleCallbackPage from "src/pages/google-callback";
import SignUpWithEmailPage from "src/pages/sign-up-with-email";
import AccountSettingsPage from "src/pages/account-settings";
import PlayChartsDetailPage from "src/pages/play-charts/detail";
import ExplanationDetailPage from "src/pages/explanation/detail";
import SetupDefinitionDetailPage from "src/pages/setup-definition/detail";

const HomePage = Loader(lazy(() => import("src/pages/home")));
const QuizTestPage = Loader(lazy(() => import("src/pages/quiz-test")));
const ChartsHistoryPage = Loader(lazy(() => import("src/pages/charts-history")));
const LearnConceptsPage = Loader(lazy(() => import("src/pages/learn-concepts")));
const QuizTestDetailPage = Loader(lazy(() => import("src/pages/quiz-test/detail")));
// const PlayChartsDetailPage = Loader(lazy(() => import("src/pages/play-charts/detail")));
const LearnConceptsDetailPage = Loader(lazy(() => import("src/pages/learn-concepts/detail")));
const ChartsHistoryDetailPage = Loader(lazy(() => import("src/pages/charts-history/detail")));

export const PUBLIC_ROUTES = [
  {
    id: "public-1",
    path: "/login",
    page: LoginPage,
    layout: MainLayout,
  },
  {
    id: "public-2",
    path: "/trading-view",
    page: TradingView,
    layout: AuthLayout,
  },
  {
    id: "public-3",
    path: "/signup-with-email",
    page: SignUpWithEmailPage,
    layout: AuthLayout,
  },
  {
    id: "public-4",
    path: "/signup-email-callback",
    page: CallbackPage,
    layout: AuthLayout,
  },
  {
    id: "public-5",
    path: "/google-callback",
    page: GoogleCallbackPage,
    layout: AuthLayout,
  },
  {
    id: "public-6",
    path: "/play-charts",
    page: PlayChartsDetailPage,
    layout: MainLayout,
  },
  {
    id: "public-7",
    path: "/",
    page: HomePage,
    layout: MainLayout,
  },
];

export const PRIVATE_ROUTES = [
  // {
  //   id: "private-1",
  //   path: "/",
  //   page: HomePage,
  //   layout: MainLayout,
  // },
  // {
  //   id: "private-2",
  //   path: "/play-charts",
  //   page: PlayChartsDetailPage,
  //   layout: MainLayout,
  // },
  // {
  //   id: "private-2",
  //   path: "/play-charts/:slug",
  //   page: PlayChartsDetailPage,
  //   layout: MainLayout,
  // },
  {
    id: "private-3",
    path: "/charts-history",
    page: ChartsHistoryPage,
    layout: MainLayout,
  },
  {
    id: "private-4",
    path: "/learn-concepts",
    page: LearnConceptsPage,
    layout: MainLayout,
  },
  {
    id: "private-8",
    path: "/learn-concepts/:slug",
    page: LearnConceptsDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-5",
    path: "/quiz-test",
    page: QuizTestPage,
    layout: MainLayout,
  },
  {
    id: "private-6",
    path: "/payment",
    page: PaymentPage,
    layout: MainLayout,
  },
  {
    id: "private-7",
    path: "/account-settings",
    page: AccountSettingsPage,
    layout: MainLayout,
  },
  {
    id: "private-9",
    path: "/quiz-test/:slug",
    page: QuizTestDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-10",
    path: "/charts-history/:slug",
    page: ChartsHistoryDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-11",
    path: "/setup-definition/:id",
    page: SetupDefinitionDetailPage,
    layout: MainLayout,
  },
  {
    id: "private-12",
    path: "/explanation/:id",
    page: ExplanationDetailPage,
    layout: MainLayout,
  },
];
