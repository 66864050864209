import { ArrowLeft, ChevronsRight, EyeIcon, User } from "lucide-react";
import { useEffect, useState, useMemo, useCallback } from "react";

import { cn } from "src/lib/utils";
import { Button } from "src/components/shadcn/Button";
import { CustomTradingView } from "src/components/common";
import { useGetSetupTypeList } from "src/hooks";
import { GrowthSightAnswerIcon } from "src/icons";
import { getPlayChartBySetUpTypeId } from "src/services/charts";
import { IChart, IPlayChart, ICheckPlayChart } from "src/models/play-charts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/components/shadcn/Card";
import { FilterChartBySetupType, SelectStar, SendUserAnswer, TittlePage } from "src/components/blocks";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/shadcn/Dialog";

const Detail = () => {
  const { data } = useGetSetupTypeList();

  // states
  const [chartData, setChartData] = useState<IChart[]>([]);
  const [currentStar, setCurrentStar] = useState<number | null>(null);
  const [playChartData, setPlayChartData] = useState<IPlayChart | null>(null);
  const [openDefinition, setOpenDefinition] = useState(false);
  const [currentSetupType, setCurrentSetupType] = useState<string>("");
  const [checkPlayChartData, setCheckPlayChartData] = useState<ICheckPlayChart | null>(null);

  // functions
  const handleGetChartByStrategyId = async (previousChartId?: string) => {
    if (!currentSetupType) return;
    setChartData([]);

    const res = await getPlayChartBySetUpTypeId(currentSetupType, previousChartId);

    if (res) {
      setPlayChartData({ ...res });
      if (res?.chart_data) {
        const newChartData = res.chart_data.map((item: IChart) => ({
          ...item,
          color: item.close > item.open ? "#01B746" : "#EE403C",
        }));
        setChartData((prev) => [...prev, ...newChartData]);
      }
    }
  };

  const handleNextChart = useCallback(() => {
    setCurrentStar(null);
    setCheckPlayChartData(null);
    handleGetChartByStrategyId(playChartData?.id);
  }, [playChartData?.id]);

  // memos
  const currentChartSetupType = useMemo(() => {
    return data?.find((item) => item.id === currentSetupType);
  }, [currentSetupType, data]);

  // effects
  useEffect(() => {
    handleGetChartByStrategyId();
  }, [currentSetupType]);

  useEffect(() => {
    if (data && !currentSetupType) {
      const firstSetup = data[0];

      if (firstSetup) {
        setCurrentSetupType(firstSetup.id);
      }
    }
  }, [data]);

  return (
    <>
      <div className="max-w-[500px] mx-auto">
        <TittlePage
          title="@GrowthSight"
          description="Is this a good setup? If yes, rank it from 1 to 5 stars!"
        />

        <FilterChartBySetupType
          currentValue={currentSetupType}
          onSetCurrentValue={setCurrentSetupType}
        />
      </div>

      <div className="space-y-2 px-2">
        {Array.isArray(chartData) && chartData?.length > 0 && (
          <div className="w-full max-w-[800px] h-[480px] mx-auto shadow-md my-4">
            <div className="w-full h-full">
              <CustomTradingView currentData={chartData} />
            </div>
          </div>
        )}

        <div className="max-w-[500px] mx-auto space-y-2">
          {checkPlayChartData ? (
            <div>
              <Button
                disabled={!currentSetupType}
                className="w-full"
                onClick={handleNextChart}
              >
                <ChevronsRight />
                <span>Next Chart</span>
              </Button>
            </div>
          ) : (
            <div>
              <Dialog
                open={openDefinition}
                onOpenChange={(open) => {
                  setOpenDefinition(open);
                }}
              >
                <DialogTrigger asChild>
                  <Button
                    disabled={!currentSetupType}
                    className="w-full"
                  >
                    <EyeIcon />
                    <span>Show Setup Definition</span>
                  </Button>
                </DialogTrigger>
                <DialogContent className="w-[100vw] h-[100svh] md:w-auto md:h-auto top-0 left-0 md:left-[50%] md:top-[50%] translate-x-0 translate-y-0 md:translate-x-[-50%] md:translate-y-[-50%] bg-transparent p-2 md:p-0 [&>button]:hidden">
                  <DialogHeader className="hidden">
                    <DialogTitle>Edit profile</DialogTitle>
                    <DialogDescription>
                      Make changes to your profile here. Click save when you're done.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="w-full h-full bg-white rounded-md p-4 space-y-[10px] overflow-y-auto">
                    <Button
                      variant="outline"
                      onClick={() => setOpenDefinition(false)}
                    >
                      <ArrowLeft />
                      <span className="text-sm leading-6 font-medium">Back</span>
                    </Button>

                    <TittlePage
                      title="@GrowthSight"
                      description={currentChartSetupType?.definition_summary ?? ""}
                    />

                    <Card>
                      <CardHeader className="hidden">
                        <CardTitle>Definition</CardTitle>
                        <CardDescription>Description</CardDescription>
                      </CardHeader>
                      <CardContent className="p-4">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentChartSetupType?.definition_details ?? "",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          )}

          {!checkPlayChartData && (
            <>
              <SelectStar
                currentStar={currentStar ?? undefined}
                setCurrentStar={setCurrentStar}
              />
              <SendUserAnswer
                setupTypeId={currentSetupType}
                chartId={playChartData?.id ?? ""}
                star={currentStar}
                setCheckPlayChartData={setCheckPlayChartData}
              />
            </>
          )}

          {checkPlayChartData && (
            <>
              <div
                className={cn(
                  "w-full p-2 rounded-md border-2 flex items-center gap-2",
                  playChartData?.score ? "border-sky-700" : "border-fuchsia-500",
                )}
              >
                <button
                  className="shrink-0 size-8 flex items-center justify-center"
                  onClick={() => {}}
                >
                  <GrowthSightAnswerIcon />
                </button>
                <div className="flex flex-col">
                  <span className="text-sm leading-6 font-bold">
                    GrowthSight's Answer
                    {!playChartData?.is_good_setup
                      ? ": Bad"
                      : `${playChartData?.score ? `${playChartData.score}-star` : ""}`}
                  </span>
                  <span className="text-sm leading-6 font-medium">{playChartData?.explanation ?? ""}</span>
                </div>
              </div>

              <div
                className={cn(
                  "w-full p-2 rounded-md border-2 flex items-center gap-2",
                  currentStar === 0 ? "border-fuchsia-500" : "border-sky-700",
                )}
              >
                <button
                  className="shrink-0 size-8 flex items-center justify-center"
                  onClick={() => {}}
                >
                  <User />
                </button>
                <div className="flex flex-col">
                  <span className="text-sm leading-6 font-bold">
                    Your Answer: {currentStar === 0 ? "Bad" : `${currentStar}-star`}
                  </span>

                  {checkPlayChartData.user_explanation && (
                    <span className="text-sm leading-6 font-medium">
                      Explanation: {checkPlayChartData.user_explanation ?? ""}
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Detail;
