import { useState } from "react";
import { ArrowLeft, Menu, LogOut } from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { cn } from "src/lib/utils";
import { MENUS } from "src/constant";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "src/components/shadcn/Sheet";
import { Button } from "src/components/shadcn/Button";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { slug } = useParams();

  // states
  const [isOpen, setIsOpen] = useState(false);

  // functions
  const handleNavigate = (path: string) => {
    if (!slug) {
      navigate(path);
    } else {
      navigate(`${path}/${slug}`);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setIsOpen(false);
    navigate("/login");
  };

  return (
    <div className="h-14 md:h-[72px] px-1 md:px-4 flex items-center justify-between">
      <ul className="flex items-center border border-slate-300 gap-1 py-1 px-[5px] rounded-[6px]">
        {MENUS.map((menu) => {
          return (
            <li
              key={`menu-${menu.id}`}
              className={cn(
                "px-3 py-[6px] rounded text-black text-sm font-medium cursor-pointer transition-all hover:bg-slate-200",
                location.pathname.includes(menu.path) ? "bg-slate-200" : "",
              )}
              onClick={() => handleNavigate(menu.path)}
            >
              {menu.title}
            </li>
          );
        })}
      </ul>

      <div className={cn("flex items-center justify-between w-fit", !localStorage.getItem("access_token") && "hidden")}>
        <Sheet
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <SheetTrigger>
            <div className="p-2 border border-slate-200 rounded-md">
              <Menu size={16} />
            </div>
          </SheetTrigger>
          <SheetContent className="[&>button]:hidden">
            <SheetHeader className="hidden">
              <SheetTitle>Are you absolutely sure?</SheetTitle>
              <SheetDescription>
                This action cannot be undone. This will permanently delete your account and remove your data from our
                servers.
              </SheetDescription>
            </SheetHeader>

            <div className="flex flex-col gap-4">
              <Button
                variant="outline"
                className="w-fit"
                onClick={() => setIsOpen(false)}
              >
                <ArrowLeft />
                <span className="text-sm leading-6 font-medium">Back</span>
              </Button>

              <Button onClick={handleLogout}>
                <LogOut />
                <span>Log Out</span>
              </Button>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};
