import { Header } from "./components/Header";
import { ScrollArea } from "src/components/shadcn/ScrollArea";

interface Props {
  children: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <section className="">
      <div className="w-full flex">
        <div className="grow">
          <Header />
          <ScrollArea className="h-[calc(100vh-72px)] py-2">
            <div className="px-1 md:px-4">{children}</div>
          </ScrollArea>
        </div>
      </div>
    </section>
  );
};

export default MainLayout;
