import moment from "moment";

import { IChart } from "src/models/play-charts";
import {
  DatafeedErrorCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SubscribeBarsCallback,
  SymbolResolveExtension,
  Timezone,
} from "src/charting_library/charting_library";

const configurationData = {
  supports_search: false,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  exchanges: [
    { value: "", name: "All Exchanges", desc: "" },
    { value: "NasdaqNM", name: "NasdaqNM", desc: "NasdaqNM" },
    { value: "NYSE", name: "NYSE", desc: "NYSE" },
  ],
  symbols_types: [
    { name: "All types", value: "" },
    { name: "Stock", value: "stock" },
    { name: "Index", value: "index" },
  ],
  supported_resolutions: ["60", "D", "2D", "3D", "W", "3W", "M", "6M"] as ResolutionString[],
};

const defaultSymbolInfo: LibrarySymbolInfo = {
  name: "",
  ticker: undefined,
  description: "",
  type: "stock",
  session: "24x7",
  exchange: "NasdaqNM",
  listed_exchange: "NasdaqNM",
  timezone: "America/New_York" as Timezone,
  minmov: 1,
  pricescale: 100,
  has_intraday: true,
  supported_resolutions: configurationData.supported_resolutions as ResolutionString[],
  currency_code: "USD",
  original_currency_code: "USD",
  data_status: "streaming",
  format: "price",
};

const createDataFeed = (data: IChart[]): IBasicDataFeed => {
  const historicalData = data.map((item) => ({
    time: moment.utc(item.date, "YYYY-MM-DD HH:mm:ss").valueOf(), // Convert date to timestamp
    open: item.open,
    high: item.high,
    low: item.low,
    close: item.close,
    volume: item.volume,
  }));

  return {
    onReady: (callback) => {
      // console.log("[onReady]: Method call", historicalData);
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
      userInput: string,
      exchange: string,
      symbolType: string,
      onResultReadyCallback: SearchSymbolsCallback,
    ) => {
      // console.log("[searchSymbols]: Method call");
    },

    resolveSymbol: (
      symbolName: string,
      onResolve: ResolveCallback,
      onError: DatafeedErrorCallback,
      extension?: SymbolResolveExtension,
    ) => {
      // console.log("[resolveSymbol]: Method call", symbolName);
      setTimeout(() => onResolve(defaultSymbolInfo));
    },

    getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
      console.log(resolution);
      try {
        const { from, to, countBack } = periodParams;

        // Filter data within the requested range
        let filteredData = historicalData.filter((item) => item.time >= from * 1000 && item.time <= to * 1000);

        // If not enough data, include earlier bars to meet countBack
        if (filteredData.length < countBack) {
          const additionalBarsNeeded = countBack - filteredData.length;

          // Get earlier data before the `from` timestamp
          const earlierData = historicalData.filter((item) => item.time < from * 1000);

          // Take the most recent bars from the earlier data
          const additionalBars = earlierData.slice(-additionalBarsNeeded);

          // Prepend the additional bars to the filtered data
          filteredData = [...additionalBars, ...filteredData];
        }

        // Sort the final data by time (ascending)
        filteredData.sort((a, b) => a.time - b.time);

        // Check if there's no data to return
        if (filteredData.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }

        // Return the data to the chart
        onHistoryCallback(filteredData, { noData: false });
      } catch (error) {
        console.error("Error in getBars:", error);
        onErrorCallback("Error fetching bars");
      }
    },

    subscribeBars: (
      symbolInfo: LibrarySymbolInfo,
      resolution: string,
      onRealtimeCallback: SubscribeBarsCallback,
      subscriberUID: string,
      onResetCacheNeededCallback: () => void,
    ) => {
      // let currentIndex = 0;
      // const interval = setInterval(() => {
      //   if (currentIndex >= historicalData.length) {
      //     clearInterval(interval);
      //     return;
      //   }
      //   const currentDataPoint = historicalData[currentIndex];
      //   const bar = {
      //     ...currentDataPoint,
      //   };
      //   onRealtimeCallback(bar);
      //   currentIndex++;
      // }, 10);
      // subscribers.set(subscriberUID, interval);
    },

    unsubscribeBars: (subscriberUID: string) => {
      // console.log("[unsubscribeBars]: Method call", subscriberUID);
      const interval = subscribers.get(subscriberUID);
      if (interval) {
        clearInterval(interval);
        subscribers.delete(subscriberUID);
      }
    },
  };
};

// Store active subscriptions
const subscribers = new Map<string, NodeJS.Timeout>();

export { createDataFeed };
